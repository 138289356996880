/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { useSnackbar } from "notistack";
import axios from "../../lib/config/axios";
import Header from "../../component/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
interface Parent {
  pseudo?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
}

export const ParentProfil = () => {
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [parent, setParent] = useState<Parent>({});
  const [newParent, setNewParent] = useState<Parent>({});
  const [enableEditPassword, setEnableEditPassword] = useState(false);

  const firstLetter = parent.pseudo?.charAt(0).toUpperCase() || "?";

  useEffect(() => {
    const fetchParentProfile = async () => {
      try {
        const response = await axios.get("/users/profile", {
          headers: { Authorization: `Bearer ${user}` },
        });
        setParent(response.data);
        setNewParent(response.data);
      } catch (err) {
        console.error("Failed to fetch profile:", err);
        enqueueSnackbar(t("errors.profile_load_failed"), {
          variant: "error",
        });
      }
    };
    fetchParentProfile();
  }, [user]);

  const handleLogout = async () => {
    try {
      await axios.post(
        "/users/logout",
        {},
        { headers: { Authorization: `Bearer ${user}` } }
      );
      logout();
      window.location.href = "/login";
    } catch {
      enqueueSnackbar(t("snackbar.logout_fail"), { variant: "error" });
    }
  };

  const handleEditProfile = async (event: React.FormEvent) => {
    event.preventDefault();
    if (newParent.password && newParent.confirmPassword) {
      if (newParent.password !== newParent.confirmPassword) {
        enqueueSnackbar(t("infos.passwords_not_match"), { variant: "error" });
        return;
      }
    }
    const data: Parent = {
      pseudo: newParent.pseudo,
      email: newParent.email,
    };
    if (newParent.password) {
      data.password = newParent.password;
    }
    try {
      await axios.put("/users/profile", data, {
        headers: { Authorization: `Bearer ${user}` },
      });
      enqueueSnackbar(t("snackbar.update_profile"), { variant: "success" });
      if (enableEditPassword) {
        setEnableEditPassword(false);
      }
    } catch (err) {
      console.error("Failed to update profile:", err);
      enqueueSnackbar(t("snackbar.error_update_profile"), {
        variant: "error",
      });
    }
  };

  const canUpdateProfile = useMemo(() => {
    if (
      parent.pseudo !== newParent.pseudo ||
      parent.email !== newParent.email ||
      newParent.password?.length === newParent.confirmPassword?.length
    ) {
      return true;
    }
    return false;
  }, [parent, newParent]);

  return (
    <div className="max-h-screen">
      <Header
        title={t("titles.about_child")}
        titleColor="blue"
        showBackToMenu={true}
      />
      <section className="container mx-auto flex flex-col items-center">
        <div className="flex flex-col items-center mt-8 gap-4 mdgap-8">
          <div className="bg-pink-500 w-32 h-32 md:w-40 md:h-40 rounded-full flex items-center justify-center text-white font-semibold text-6xl shadow-lg">
            {firstLetter}
          </div>
          <button
            onClick={handleLogout}
            className="mt-4 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
          >
            {t("children.logout")}
            <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200 ml-2">
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                style={{ color: "#ffffff" }}
              />
            </span>
          </button>
        </div>

        {/* Editable Profile Form */}
        <div className="my-8 md:my-12 w-full md:w-2/3 lg:w-3/5">
          <form
            className="flex flex-col mx-6 items-center bg-white p-6 rounded-lg shadow-lg space-y-4 lg:space-y-6"
            onSubmit={handleEditProfile}
          >
            <div className="flex flex-col lg:flex-row lg:items-center w-full gap-4 justify-between">
              <label className="text-gray-600 font-semibold">
                {t("infos.pseudo")}
              </label>
              <input
                type="text"
                value={newParent.pseudo}
                onChange={(e) =>
                  setNewParent({ ...newParent, pseudo: e.target.value })
                }
                placeholder={newParent.pseudo || t("placeholders.username")}
                className="flex lg:w-3/5 py-2 px-4 rounded-lg border bg-white text-gray-700 transition duration-200 focus:outline-none focus:border-blue-400"
              />
            </div>

            <div className="flex flex-col lg:flex-row lg:items-center w-full gap-4 justify-between">
              <label className="text-gray-600 font-semibold">
                {t("infos.email")}
              </label>
              <input
                type="email"
                value={newParent.email}
                onChange={(e) =>
                  setNewParent({ ...newParent, email: e.target.value })
                }
                placeholder={newParent.email || t("placeholders.email")}
                className="flex lg:w-3/5 py-2 px-4 rounded-lg border bg-white text-gray-700 transition duration-200 focus:outline-none focus:border-blue-400"
              />
            </div>

            {enableEditPassword && (
              <>
                <div className="flex flex-col lg:flex-row lg:items-center w-full gap-4 justify-between">
                  <label className="text-gray-600 font-semibold">
                    {t("register.password")}
                  </label>
                  <input
                    type="password"
                    onChange={(e) =>
                      setNewParent({ ...newParent, password: e.target.value })
                    }
                    placeholder={t("infos.enter_new_password")}
                    className="flex lg:w-3/5 py-2 px-4 rounded-lg border focus:border-blue-300 bg-white text-gray-700 focus:outline-none"
                  />
                </div>
                <div className="flex flex-col lg:flex-row lg:items-center w-full gap-4 justify-between">
                  <label className="text-gray-600 font-semibold">
                    {t("register.password_confirmation")}
                  </label>
                  <input
                    type="password"
                    onChange={(e) =>
                      setNewParent({
                        ...newParent,
                        confirmPassword: e.target.value,
                      })
                    }
                    placeholder={t("infos.new_password_confirmation")}
                    className="flex lg:w-3/5 py-2 px-4 rounded-lg border focus:border-blue-300 bg-white text-gray-700 focus:outline-none"
                  />
                </div>
              </>
            )}

            {/* Toggle and Save Button */}
            <div className="flex items-center justify-center w-full space-x-6">
              <label className="flex items-center cursor-pointer">
                <span className="text-gray-600 font-semibold mr-3">
                  {t("infos.edit_password")}
                </span>
                <input
                  type="checkbox"
                  checked={enableEditPassword}
                  onChange={() => setEnableEditPassword(!enableEditPassword)}
                  className="sr-only"
                />
                <div
                  className={`relative w-10 h-5 rounded-lg transition-colors duration-300 ${
                    enableEditPassword ? "bg-blue-300" : "bg-gray-300"
                  }`}
                >
                  <span
                    className={`absolute top-0.5 left-0.5 w-4 h-4 bg-white rounded-lg transform transition-transform ${
                      enableEditPassword ? "translate-x-5" : ""
                    }`}
                  ></span>
                </div>
              </label>
            </div>
            <button
              type="submit"
              className={`bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform ${
                !canUpdateProfile
                  ? "opacity-50 cursor-not-allowed bg-gray-400" // Style pour état disabled
                  : "hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200"
              } flex items-center space-x-2`}
              disabled={!canUpdateProfile}
            >
              {t("buttons.update")}
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};
