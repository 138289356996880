import React from "react";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import logo from "../assets/logo/logo_bassist.png";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function NotFound() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  function handleGoBack() {
    navigate(user ? "/children" : "/login");
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white flex items-center justify-center px-4">
      <div className="max-w-lg w-full text-center flex flex-col items-center space-y-8 p-8">
        {/* Logo */}
        <div className="mb-8 relative">
          <div className="absolute inset-0 bg-blue-100 rounded-full blur-xl opacity-50"></div>
          <img
            src={logo}
            alt="Bassist Logo"
            className="h-32 mx-auto relative transform rotate-45 animate-[bounce_2s_infinite]"
          />
        </div>

        {/* Texte principal */}
        <h1 className="text-6xl font-bold text-gray-900 mb-4">
          {t("not_foud.title")}
        </h1>
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          {t("not_foud.message")}
        </h2>
        <p className="text-gray-600 mb-8">{t("not_foud.joke")}</p>

        {/* Bouton de retour */}
        {/* <button
          onClick={handleGoBack}
          className="inline-flex items-center px-6 py-3 text-lg font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300 transition-colors duration-200"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          {t("buttons.return")}
        </button> */}
        <button
          onClick={handleGoBack}
          className="bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex self-center items-center space-x-2"
        >
          <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
            <FontAwesomeIcon icon={faArrowLeft} className="text-white" />
          </span>
          <span>{t("buttons.return")}</span>
        </button>
      </div>
    </div>
  );
}
