import React, { useEffect, useState } from "react";
import { ButtonText } from "../component/Button";
import { useTranslation } from "react-i18next";
import Header from "../component/Header";
import { useAuth } from "../hooks/useAuth";
import { useParams } from "react-router-dom";
import axios from "../lib/config/axios";
import { useSnackbar } from "notistack";
import { Download } from "lucide-react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type UploadedFile = {
  fileType: string;
  file: string;
};

const PARENTAL_AUTHORIZATIONS = ["care_authorization"];

const MEDICAL_CERTIFICATES = [
  "sick_day",
  "sports_exemption",
  "school_exclusion",
  "PAI",
  "other",
];

const PRESCRIPTIONS = ["usual_prescription", "punctual_prescription"];

export const Administratif = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDocument, setSelectedDocument] =
    useState<string>("care_authorization");
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [openModal, setOpenModal] = useState(false);

  const handleShowDoc = (doc: string) => {
    const screenSize = window.innerWidth;
    if (screenSize < 1024) {
      setOpenModal(true);
    }
    setSelectedDocument(doc);
  };

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let selectedFile;
    if (event.target.files && event.target.files[0]) {
      selectedFile = event.target.files[0];
    }
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("fileType", selectedDocument);
    try {
      await axios.post(
        `/children/${child.id}/administration/upload-file`,
        formData,
        {
          headers: { Authorization: `Bearer ${user}` },
        }
      );
      fetchFilesForChild();
      enqueueSnackbar(t("snackbar.file_success"), {
        autoHideDuration: 3000,
        variant: "success",
      });
    } catch (error) {
      console.error("Error adding consultation:", error);
    }
  };

  const fetchFilesForChild = async () => {
    const fetch = await axios.get(`/children/${child.id}/administration`, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });

    if (fetch.status === 200 && fetch.data) {
      setUploadedFiles(fetch.data);
    }
  };

  const downloadFileFromUrl = (fileUrl: string, fileName: string) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fileName || "file.pdf");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const getTemplateFile = async (fileType: string) => {
    try {
      const response = await axios.get(
        `/children/${child.id}/administration/templates/${fileType}`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );

      const fileUrl = response.data;
      const fileName = `${fileType}.pdf`;
      downloadFileFromUrl(fileUrl, fileName);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const downloadMyVersion = (fileType: string) => {
    const file = uploadedFiles.find((file) => file.fileType === fileType);
    if (!file) {
      return;
    }
    const fileUrl = file.file;
    const fileName = `${fileType}.pdf`;
    downloadFileFromUrl(fileUrl, fileName);
  };

  useEffect(() => {
    fetchFilesForChild();
  }, []);

  return (
    <div className="w-screen">
      <Header
        title={t("titles.administrative")}
        titleColor="green"
        showBackToMenu={true}
      />
      <div className="flex">
        <input
          id="pdf-upload"
          type="file"
          accept=".pdf"
          onChange={uploadFile}
          style={{ display: "none" }}
        />
        <section className="flex flex-1 flex-col justify-between lg:px-12 px-3">
          <div className="flex flex-col justify-start">
            <h2 className="block lg:my-8 my-4 lg:text-xl text-lg font-semibold underline text-gray-900 ">
              {t("administrative.parental_authorization")}
            </h2>
            <div className="flex flex-row gap-4">
              {PARENTAL_AUTHORIZATIONS.map((type) => (
                <ButtonText
                  key={type}
                  color="black"
                  text={t(`administrative.button_text.${type}`)}
                  onclick={() => handleShowDoc(type)}
                />
              ))}
            </div>
            <h2 className="flex flex-col lg:my-8 my-4 lg:text-xl text-lg font-semibold underline text-gray-900 ">
              {t("administrative.medical_certificate")}
            </h2>
            <div className="flex flex-row lg:gap-4 gap-2 flex-wrap">
              {MEDICAL_CERTIFICATES.map((type) => (
                <ButtonText
                  key={type}
                  color="black"
                  text={t(`administrative.button_text.${type}`)}
                  onclick={() => handleShowDoc(type)}
                />
              ))}
            </div>
            <h2 className="block lg:my-8 my-4 lg:text-xl text-lg font-semibold underline text-gray-900 ">
              {t("administrative.download_prescription")}
            </h2>
            <div className="flex flex-row lg:gap-4 gap-2">
              {PRESCRIPTIONS.map((type) => (
                <ButtonText
                  key={type}
                  color="black"
                  text={t(`administrative.button_text.${type}`)}
                  onclick={() => handleShowDoc(type)}
                />
              ))}
            </div>
          </div>
        </section>
        <div className="lg:flex hidden min-h-px 364px w-0.5 bg-slate-300 mt-20" />
        <section className="lg:flex hidden flex-1 flex-col justify-between px-12">
          <div className="flex align-center justify-between lg:my-8 my-4 lg:text-2xl">
            <h2 className="flex flex-col lg:mt-8 mt-4 lg:text-xl text-lg font-semibold underline text-gray-900">
              {t(`administrative.button_text.${selectedDocument}`)}
            </h2>
          </div>
          <div className="flex flex-row items-center justify-between lg:gap-4 gap-2">
            <button
              type="submit"
              className="bg-gradient-to-r w-3/4 from-[#4CAF50] via-[#66BB6A] to-[#81C784] text-white font-medium text-base tems-center justify-center mb-4 py-2 px-4 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-[#A5D6A7] flex items-center"
            >
              <label
                htmlFor="pdf-upload"
                className="flex items-center cursor-pointer space-x-2"
              >
                <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                  <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
                </span>
                <span>{t("administrative.button_text.import_new_doc")}</span>
              </label>
            </button>

            <button
              onClick={() => getTemplateFile(selectedDocument)}
              type="submit"
              className="bg-gradient-to-r w-3/4 from-blue-400 to-blue-300 text-white font-medium text-base tems-center justify-center mb-4 py-2 px-4 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
            >
              <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                <Download size={16} className="h-6" />
              </span>
              <span>{t("administrative.button_text.download_original")}</span>
            </button>
          </div>
          <iframe
            src={
              uploadedFiles.find((file) => file.fileType === selectedDocument)
                ?.file
            }
            style={{
              width: "100%",
              height: "auto",
              aspectRatio: "1 / 1.1",
              paddingBottom: "24px",
            }}
            title="PDF Preview"
          ></iframe>
        </section>
      </div>

      {openModal && (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg md:w-11/12 w-10/12">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold">
                {t(`administrative.button_text.${selectedDocument}`)}
              </h2>
              <button onClick={() => setOpenModal(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-col items-center pt-4">
              <button
                onClick={() => getTemplateFile(selectedDocument)}
                type="submit"
                className="bg-gradient-to-r w-fits from-blue-400 to-blue-300 text-white font-medium text-base tems-center justify-center mb-4 py-2 px-4 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
              >
                <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                  <Download size={16} className="h-6" />
                </span>
                <span>{t("administrative.button_text.download_original")}</span>
              </button>
              <button
                onClick={() => downloadMyVersion(selectedDocument)}
                type="submit"
                className="bg-gradient-to-r w-fits from-blue-400 to-blue-300 text-white font-medium text-base tems-center justify-center mb-4 py-2 px-4 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center"
              >
                <label
                  htmlFor="pdf-upload"
                  className="flex items-center cursor-pointer space-x-2"
                >
                  <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{ color: "#ffffff" }}
                    />
                  </span>
                  <span>{t("administrative.button_text.download_my_doc")}</span>
                </label>
              </button>
              <button
                type="submit"
                className="bg-gradient-to-r w-fits from-[#4CAF50] via-[#66BB6A] to-[#81C784] text-white font-medium text-base tems-center justify-center mb-4 py-2 px-4 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-[#A5D6A7] flex items-center"
              >
                <label
                  htmlFor="pdf-upload"
                  className="flex items-center cursor-pointer space-x-1"
                >
                  <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                    <Download size={16} className="h-6" />
                  </span>
                  <span>{t("administrative.button_text.import_new_doc")}</span>
                </label>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
