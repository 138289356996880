/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from "react";
import { ChildCard } from "../component/ChildCard";
import { WaveTitle } from "../component/WaveTitle";
import Pharmacy from "../assets/icons/64-removebg-preview.png";
import Phone from "../assets/icons/63-removebg-preview.png";
import Learn from "../assets/icons/he.png";
import { useTranslation } from "react-i18next";
import { ButtonIcon } from "../component/Button";
import axios from "../lib/config/axios";
import { useAuth } from "../hooks/useAuth";
import { useSnackbar } from "notistack";
import { useUpdateContext } from "../contexts/UpdateContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";

interface Child {
  id: string;
  image_id: string;
  firstname: string;
}

export const Children = () => {
  const { t } = useTranslation();
  const [children, setChildren] = useState<Child[]>([]);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { update } = useUpdateContext();

  useEffect(() => {
    const fetchChildren = async () => {
      try {
        const response = await axios.get("/children/all", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        setChildren(response.data);
        response.data.forEach((child: Child) => {
          localStorage.setItem(child.id.toString(), JSON.stringify(child));
        });
      } catch (error) {
        console.error(error);
      }
    };
    fetchChildren();
  }, [user, update]);

  const handleLogout = () => {
    axios
      .post(
        "/users/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          logout();
          window.location.href = "/login";
        } else {
          throw new Error("Failed to logout");
        }
      })
      .catch(() => {
        enqueueSnackbar(t("snackbar.logout_fail"), {
          autoHideDuration: 2000,
          variant: "error",
        });
      });
  };

  return (
    <div>
      <section className="flex flex-col gap-y-8 mx-4 md:mx-12 py-8 ">
        <div className="flex justify-between items-end mb-6">
          <WaveTitle
            color="blue"
            title={t("titles.children")}
            titleColor="black"
          />
          <button
            onClick={handleLogout}
            className="mt-4 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium  text-sm md:text-base py-1 px-2 md:py-2 md:px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
          >
            {t("children.logout")}
            <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200 ml-2">
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                style={{ color: "#ffffff" }}
              />
            </span>
          </button>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-8 justify-center items-center md:my-8">
          {children.map((child) => (
            <ChildCard
              key={child.id}
              picture={child.image_id}
              name={child.firstname}
              urlRedirection={`/about-child/${child.id}`}
              hoverColor="blue-300"
              height="md:h-48 h-36 w-auto"
              fontSize="xl"
            />
          ))}
          <ChildCard
            picture="AddChild"
            name={t("children.add_child")}
            urlRedirection="/new-child"
            hoverColor="pink-500"
            height="h-20 md:h-32 w-auto"
            fontSize="xl"
            imageContainerClassName="h-28 w-28 md:w-48 md:h-48 flex items-center justify-center"
          />
        </div>

        <div className="mt-6 flex md:flex-row flex-col justify-center items-center my-4 gap-6 lg:gap-16">
          <ButtonIcon
            text={t("children.pharmacy_box")}
            color="blue-300"
            urlRedirection="/pharmacy-box"
            icon={Pharmacy}
            width="80"
            disabled={false}
            className="min-w-60"
          />
          <ButtonIcon
            text={t("children.useful_numbers")}
            color="blue-300"
            urlRedirection="/children"
            icon={Phone}
            width="80"
            disabled={false}
            className="min-w-60"
          />
          <ButtonIcon
            text={t("children.learning")}
            color="blue-300"
            urlRedirection="/children"
            icon={Learn}
            width="80"
            disabled={false}
            className="min-w-60"
          />
        </div>
      </section>
    </div>
  );
};
