/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import "./TableCheck.scss";
import CheckSVG from "../../assets/icons/Check";
import PendingSVG from "../../assets/icons/Pending";
import {
  faCircleCheck,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

interface TableCheckProps {
  tableList: any[];
  data: any[];
  setData: any;
  columns: { key: string; value: string }[];
  lines: { key: string; value: string }[];
  columnsName: { key: string; title: string };
  onSave?: () => void;
}

const TableCheck: React.FC<TableCheckProps> = ({
  tableList,
  data,
  setData,
  columns,
  lines,
  columnsName,
  onSave,
}) => {
  const { t } = useTranslation();
  const [hasChanged, setHasChanged] = useState(false);
  const [cellStatus, setCellStatus] = useState<{
    [key: string]: "Completed" | "Pending" | "";
  }>({});
  const [selectedVaccin, setSelectedVaccin] = useState<any>(null);
  const [vaccinDate, setVaccinDate] = useState("");

  useEffect(() => {
    const initialStatus: { [key: string]: "Completed" | "Pending" | "" } = {};
    tableList.forEach((d) => {
      const key = `${d.columnsName}`;
      const idx = data.findIndex(
        (item) => item.vaccination.columnsName === key
      );
      if (idx > -1) {
        initialStatus[key] = "Completed";
      } else {
        initialStatus[key] = "Pending";
      }
    });
    setCellStatus(initialStatus);
  }, [data, tableList]);

  useEffect(() => {
    if (selectedVaccin) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [selectedVaccin]);

  const handleToggle = (line: string, col: string) => {
    const key = `${line}-${col}`;
    const idxExist = data.findIndex((d) => d.vaccination.columnsName === key);

    if (idxExist > -1) {
      setSelectedVaccin({
        key,
        title: col,
        age: line,
        date: data[idxExist].vaccineDate,
      });
      setVaccinDate(
        new Date(data[idxExist].vaccineDate).toISOString().split("T")[0]
      );
    } else {
      const vaccin = tableList.find((t) => t.columnsName === key);
      setSelectedVaccin({ key, title: vaccin?.title || col, age: line });
      setVaccinDate("");
    }
  };

  const handleSaveDate = async () => {
    const formattedKey = `${selectedVaccin.key}`;

    const item = {
      vaccinationId: tableList.find((t) => t.columnsName === selectedVaccin.key)
        ?.id,
      vaccination: {
        columnsName: formattedKey,
      },
      status: "DONE",
      vaccineDate: vaccinDate,
    };

    setData(
      (
        prevData: {
          vaccinationId: any;
          columnsName: string;
          status: string;
          vaccineDate: string;
        }[]
      ) => {
        const idx = prevData.findIndex(
          // @ts-expect-error error
          (d) => d.vaccination.columnsName === formattedKey
        );
        if (idx > -1) {
          prevData[idx] = {
            ...prevData[idx],
            vaccineDate: new Date(vaccinDate).toISOString(),
          };
        } else {
          // @ts-expect-error error
          prevData.push(item);
        }
        return [...prevData];
      }
    );

    setCellStatus((prev) => ({
      ...prev,
      [selectedVaccin.key]: "Completed",
    }));

    // await sendDateToBackend(item);
    setSelectedVaccin(null);
    setHasChanged(true);
  };

  // const sendDateToBackend = async (item: {
  //   vaccinationId: any;
  //   columnsName: string;
  //   vaccineDate: string;
  // }) => {
  //   try {
  //     const response = await fetch("/api/save-vaccine-date", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(item),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Erreur lors de l'envoi de la date au serveur.");
  //     }
  //   } catch (error) {
  //     console.error("Erreur:", error);
  //   }
  // };

  const handleDeleteDate = () => {
    const formattedKey = `${selectedVaccin.key}`;
    setData((prevData: any[]) =>
      prevData.filter(
        (d: { columnsName: string }) =>
          // @ts-expect-error error
          d.vaccination.columnsName !== formattedKey
      )
    );
    setCellStatus((prev) => ({
      ...prev,
      [selectedVaccin.key]: "Pending",
    }));
    setSelectedVaccin(null);
    setHasChanged(true);
  };

  const renderCell = (line: string, col: string) => {
    const key = `${line}-${col}`;
    const cellData = data.find((item) => item.vaccination.columnsName === key);

    if (cellData && cellData.vaccineDate) {
      return (
        <td
          key={key}
          className="cell cell-with-date"
          onClick={() => handleToggle(line, col)}
        >
          <span className="formatted-date">
            {new Date(cellData.vaccineDate).toLocaleDateString(t("days.lg"), {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </span>
        </td>
      );
    }

    const iconByStatus = {
      Completed: <CheckSVG />,
      Pending: <PendingSVG />,
      "": "",
    };
    return (
      <td
        key={key}
        className={
          cellStatus[key] === "Pending" || cellStatus[key] === "Completed"
            ? "cell"
            : "cell-disabled"
        }
        onClick={
          cellStatus[key] === "Pending" || cellStatus[key] === "Completed"
            ? () => handleToggle(line, col)
            : () => {}
        }
      >
        {iconByStatus[cellStatus[key]]}
      </td>
    );
  };

  return (
    <div className="table-div">
      <table className="check-table">
        <thead>
          <tr>
            <th>{columnsName.title}</th>
            {columns.map((col) => (
              <th key={col.key}>{col.value}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {lines.map((line) => (
            <tr key={line.key}>
              <td>{line.value}</td>
              {columns.map((col) => renderCell(line.key, col.key))}
            </tr>
          ))}
        </tbody>
      </table>
      {onSave && hasChanged && (
        <button
          onClick={onSave}
          type="button"
          className="mt-4 bg-gradient-to-r from-[#4CAF50] via-[#66BB6A] to-[#81C784] text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-[#A5D6A7] flex items-center space-x-2"
        >
          <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: "#ffffff" }}
            />
          </span>
          <span>{t("buttons.update")}</span>
        </button>
      )}
      {selectedVaccin && (
        <div className="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-4/5 md:w-1/3">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold mb-2">
                {"Veuillez sélectionner la date :"}
              </h3>
              <button onClick={() => setSelectedVaccin(null)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <label className="block text-sm font-medium">
              {t("new_child.form_label.vaccin_date")}
            </label>
            <input
              type="date"
              value={vaccinDate}
              onChange={(e) => setVaccinDate(e.target.value)}
              className="border border-gray-300 rounded-md p-2 mt-2 w-full"
            />
            <div className="flex flex-col md:flex-row items-center justify-center gap-4 md:space-x-4 sm:mt-4">
              {/* <button
                onClick={() => setSelectedVaccin(null)}
                type="button"
                className="w-full flex justify-center bg-gradient-to-r from-gray-400 to-gray-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-200 items-center space-x-2"
              >
                <span>{t("buttons.cancel")}</span>
              </button> */}
              <button
                onClick={handleDeleteDate}
                type="button"
                className="w-full flex justify-center bg-gradient-to-r from-red-400 to-red-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-red-200 items-center space-x-2"
              >
                <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    style={{ color: "#ffffff" }}
                  />
                </span>
                <span>{t("buttons.delete")}</span>
              </button>
              <button
                onClick={handleSaveDate}
                type="button"
                className="w-full flex justify-center bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 items-center space-x-2"
              >
                <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                  <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
                </span>
                <span>{t("buttons.validate")}</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableCheck;
