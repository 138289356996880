/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import axios from "../lib/config/axios";

import Temperature from "../assets/icons/46-removebg-preview.png";
import ToiletPaper from "../assets/icons/47-removebg-preview.png";
import Vomit from "../assets/icons/48-removebg-preview.png";
import Bottle from "../assets/icons/51-removebg-preview.png";
import Camera from "../assets/icons/73-removebg-preview.png";
import Pills from "../assets/icons/56-removebg-preview.png";
import Balance from "../assets/icons/58-removebg-preview.png";
import ArrowLeft from "../assets/icons/Arrow/ArrowLeft";
import ArrowRight from "../assets/icons/Arrow/ArrowRight";
import Header from "../component/Header";
import { availablesMonitorings } from "./Monitoring/constants";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import { GridExample } from '../component/Tables';

// const availablesMonitorings = [
//   {
//     id: 1,
//     clickable_url: (id: string) => `/temperature/${id}`,
//     field_name: "temperature",
//     name: "Temperature",
//     form: {
//       fields: [
//         {
//           field_name: "degrees",
//           name: "Degrès",
//           type: "number",
//         },
//         // Méthode de prise aussi ?
//       ],
//     },
//     getUrl: (id: string) => `/children/${id}/temperature-monitoring`,
//     postUrl: (id: string) => `/children/${id}/temperature-monitoring`,
//   },
//   {
//     id: 2,
//     clickable_url: (id: string) => `/treatment/${id}`,
//     field_name: "medication",
//     name: "Médicaments",
//     form: {
//       fields: [
//         {
//           field_name: "medication",
//           name: "Nom",
//           type: "text",
//         },
//         {
//           field_name: "dosage",
//           name: "Quantité",
//           type: "text",
//         },
//       ],
//     },
//     getUrl: (id: string) => `/children/${id}/medication-monitoring`,
//     postUrl: (id: string) => `/children/${id}/medication-monitoring`,
//   },
//   {
//     id: 3,
//     clickable_url: (id: string) => `/feeding/${id}`,
//     field_name: "feeding",
//     name: "Alimentation",
//     form: {
//       fields: [
//         // en ml
//         {
//           field_name: "quantity",
//           name: "Quantité",
//           type: "number",
//         },
//         // {
//         //   name: "Type",
//         //   type: "select",
//         //   options: ["Lait", "Solide"],
//         // },
//       ],
//     },
//     getUrl: (id: string) => `/children/${id}/feeding-monitoring`,
//     postUrl: (id: string) => `/children/${id}/feeding-monitoring`,
//   },
//   {
//     id: 4,
//     clickable_url: (id: string) => `/weight/${id}`,
//     field_name: "weight",
//     name: "Poids",
//     form: {
//       fields: [
//         {
//           field_name: "value",
//           name: "Poids",
//           type: "number",
//         },
//       ],
//     },
//     getUrl: (id: string) => `/children/${id}/weight-monitoring`,
//     postUrl: (id: string) => `/children/${id}/weight-monitoring`,
//   },
//   {
//     id: 5,
//     clickable_url: (id: string) => `/vomiting/${id}`,
//     field_name: "vomiting",
//     name: "Vomissements",
//     form: {
//       fields: [
//         {
//           field_name: "quantity",
//           name: "Quantité",
//           type: "select",
//           options: ["Peu", "Moyen", "Beaucoup"],
//         },
//         {
//           field_name: "description",
//           name: "Description",
//           type: "text",
//         },
//       ],
//     },
//     getUrl: (id: string) => `/children/${id}/vomiting-monitoring`,
//     postUrl: (id: string) => `/children/${id}/vomiting-monitoring`,
//   },
//   {
//     id: 6,
//     clickable_url: (id: string) => `/poop/${id}`,
//     field_name: "stool",
//     name: "Selles",
//     form: {
//       fields: [
//         {
//           field_name: "consistency",
//           name: "Type",
//           type: "select",
//           options: ["Dure", "Molle", "Liquide"],
//         },
//         {
//           field_name: "color",
//           name: "Couleur",
//           type: "select",
//           options: ["Jaune", "Vert", "Noir", "Rouge"],
//         },
//         {
//           field_name: "quantity",
//           name: "Quantité",
//           type: "select",
//           options: ["Peu", "Moyen", "Beaucoup"],
//         },
//       ],
//     },
//     getUrl: (id: string) => `/children/${id}/stool-monitoring`,
//     postUrl: (id: string) => `/children/${id}/stool-monitoring`,
//   },
// ];

const MonitorModal = ({
  setOpen,
  carePlanDate,
  hour,
  monitoring,
  id,
  user,
  pathologyId,
}: any) => {
  const [form, setForm] = useState<any>({});
  const { t } = useTranslation();
  const matchingForm = availablesMonitorings.find(
    (m) => m.name === monitoring
  )?.form;
  const navigate = useNavigate();

  const monitoringDate = new Date(carePlanDate);
  monitoringDate.setHours(hour);
  monitoringDate.setMinutes(0);
  monitoringDate.setSeconds(0);

  const postUrl = availablesMonitorings
    .find((m) => m.name === monitoring)
    ?.postUrl(id);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!postUrl) return;
    const response = await axios.post(
      postUrl,
      {
        ...form,
        dateTime: monitoringDate.toISOString(),
        sourcePathologyId: pathologyId,
      },
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );

    if (response.status === 201) {
      navigate(0);
    } else {
      console.log("error");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-white w-2/3 md:w-1/2 xl:w-1/3 p-8 rounded-lg">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">
            {t("titles.new_data")} {monitoring}
          </h2>
          <button onClick={() => setOpen(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <form className="flex flex-col mt-4 gap-4" onSubmit={handleSubmit}>
          {matchingForm?.fields.map((field: any) => (
            <div key={field.name} className="flex flex-col gap-2">
              <label htmlFor={field.name}>{t(field.name)}</label>
              {field.type === "select" ? (
                <select
                  value={form[field.field_name] || ""}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      [field.field_name]: e.target.value,
                    })
                  }
                  id={field.name}
                  name={field.name}
                  className="border border-gray-300 py-2 px-4 rounded-lg shadow-sm focus:ring-1 focus:ring-blue-500 focus:outline-none"
                >
                  <option value="" disabled>
                    {t("care_plan.select")}
                  </option>
                  {field.options.map((option: string) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  value={form[field.field_name]}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      [field.field_name]:
                        field.type === "number"
                          ? Number(e.target.value)
                          : e.target.value,
                    })
                  }
                  type={field.type}
                  id={field.name}
                  name={field.name}
                  className="border border-gray-300 py-2 px-4 rounded-lg shadow-sm focus:ring-1 focus:ring-blue-500 focus:outline-none"
                />
              )}
            </div>
          ))}
          <button
            type="submit"
            className="mt-2 w-2/3 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex justify-center items-center self-center space-x-2"
          >
            <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
              <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
            </span>
            <span>{t("buttons.add")}</span>
          </button>
        </form>
      </div>
    </div>
  );
};

const NewCarePlanModal = ({ t, setOpen, id, user }: any) => {
  const [form, setForm] = useState<any>({});
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const response = await axios.post(`/children/${id}/care-plan`, form, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });

    if (response.status === 201) {
      setOpen(false);
    } else {
      console.log("error");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-white w-2/3 md:w-1/2 xl:w-1/2 p-8 rounded-lg">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">{t("titles.new_care_plan")}</h2>
          <button onClick={() => setOpen(false)}>
            <svg
              xmlns="http://www.w3.org/
              2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <form className="mt-5 flex flex-col gap-y-4">
          <label className="font-bold">{t("care_plan.end_date")}</label>
          <input
            type="date"
            value={form.endDate}
            onChange={(e) =>
              setForm({
                ...form,
                endDate: e.target.value,
              })
            }
            className="border border-gray-300 rounded-md p-2"
          />
        </form>
        <div>
          <h3 className="mt-4 font-bold">{t("care_plan.chose_monitoring")}</h3>
          <section className="flex flex-col justify-center">
            <div className="grid gap-6 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 p-6 max-w-6xl">
              {[
                {
                  text: "Température",
                  icon: Temperature,
                },
                {
                  text: "Adm. Médicaments",
                  icon: Pills,
                },
                {
                  text: "Vomissements",
                  icon: Vomit,
                },
                {
                  text: "Alimentation",
                  icon: Bottle,
                },
                {
                  text: "Selles",
                  icon: ToiletPaper,
                },
                {
                  text: "Poids",
                  icon: Balance,
                },
                {
                  text: "Img. des symptômes",
                  icon: Camera,
                  disabled: true,
                },
              ].map(({ text, icon, disabled = false }, index) => (
                <button
                  key={index}
                  className={`min-h-[120px] relative flex flex-col items-center justify-center p-4 lg;p-5 w-full border-2 border-blue-300
                    rounded-xl shadow-lg transition-transform duration-500 ease-out
                    hover:shadow-xl hover:scale-105 transform hover:rotate-1 hover:bg-blue-50
                    ${selectedIndex === index ? "bg-blue-100" : "bg-white"}
                    ${disabled ? "bg-gray-200 border-gray-300 cursor-not-allowed text-gray-500" : ""}
                    `}
                  onClick={() => {
                    setSelectedIndex(index);
                  }}
                  disabled={disabled}
                >
                  <img
                    src={icon}
                    alt={`icon for ${text}`}
                    className="w-10 h-10 md:w-12 md:h-12 transition-transform duration-300 hover:scale-110"
                  />
                  <span className="mt-2 md:text-sm lg:text-md font-semibold text-center">
                    {text}
                  </span>
                  {!disabled && (
                    <div className="absolute inset-0 rounded-xl opacity-0 hover:opacity-20 bg-blue-100 transition-opacity duration-500" />
                  )}
                </button>
              ))}
            </div>
          </section>
        </div>
        <div className="w-full flex justify-center">
          <button
            type="submit"
            className="mt-2 w-2/3 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex justify-center items-center self-center space-x-2"
          >
            <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
              <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
            </span>
            <span>{t("buttons.add")}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export const CarePlan = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");

  const [carePlanDate, setCarePlanDate] = useState(new Date());
  const [hour, setHour] = useState(-1);
  const [monitoring, setMonitoring] = useState("");
  const [open, setOpen] = useState(false);
  const [openNewModal, setOpenNewModal] = useState(false);
  const [currentCarePlan, setCurrentCarePlan] = useState<any>([]);
  const [carePlanData, setCarePlanData] = useState<any>([]);
  const [monitorings, setMonitorings] = useState<any>({});

  const handleNextDate = () => {
    setCarePlanDate(new Date(carePlanDate.setDate(carePlanDate.getDate() + 1)));
  };

  const handlePreviousDate = () => {
    setCarePlanDate(new Date(carePlanDate.setDate(carePlanDate.getDate() - 1)));
  };

  useEffect(() => {
    const fetchConsultations = async () => {
      const response = await axios.get(`/children/${id}/consultations/`, {
        headers: { Authorization: `Bearer ${user}` },
      });

      if (response.data.length === 0) {
        return;
      }

      setCarePlanData(
        // @ts-expect-error error
        response.data.reduce((latest, item) => {
          return new Date(item.createdAt) > new Date(latest.createdAt)
            ? item
            : latest;
        })
      );

      const carePlanTitles = [
        ...new Set(
          response.data.flatMap((obj: any) =>
            obj.pathology.carePlans.map((plan: any) => plan.title)
          )
        ),
      ];

      const filteredMonitorings = availablesMonitorings.filter((monitoring) =>
        carePlanTitles.includes(monitoring.name)
      );

      setCurrentCarePlan(filteredMonitorings);
    };

    const fetchCurrentMonitorings = async () => {
      for (const monitoring of availablesMonitorings) {
        if (!id) return;
        const response = await axios.get(monitoring.getUrl(id), {
          headers: { Authorization: `Bearer ${user}` },
        });

        setMonitorings((prev: any) => ({
          ...prev,
          [monitoring.field_name]: response.data,
        }));
      }
    };

    fetchConsultations();
    fetchCurrentMonitorings();
  }, []);

  const alreadyAdded = (
    index: number,
    carePlanDate: Date,
    monitorings?: any
  ) => {
    return monitorings?.find(
      (m: any) =>
        new Date(m.dateTime).getHours() === index &&
        new Date(m.dateTime).toLocaleDateString(t("days.lg")) ===
          carePlanDate.toLocaleDateString(t("days.lg"))
    );
  };

  const handleToggleModal = (
    hour: number,
    monitoring: string,
    alreadyAdded: boolean
  ) => {
    if (alreadyAdded) return;
    setHour(hour);
    setMonitoring(monitoring);
    setOpen(!open);
  };

  return (
    <div className="w-screen">
      <Header
        title={t("titles.care_plan")}
        titleColor="blue"
        childInfo={{
          picture: child.image_id,
          name: child.firstname,
          urlRedirection: `/child-profile/${child.id}`,
        }}
        showBackToMenu={true}
      />

      {open && (
        <MonitorModal
          setOpen={setOpen}
          carePlanDate={carePlanDate}
          hour={hour}
          monitoring={monitoring}
          id={id}
          user={user}
          pathologyId={carePlanData.pathologyId ?? null}
        />
      )}
      {openNewModal && (
        <NewCarePlanModal t={t} setOpen={setOpenNewModal} id={id} user={user} />
      )}
      {currentCarePlan.length > 0 ? (
        <section className="flex flex-col justify-between px-12 w-full">
          <div className="flex flex-row justify-center items-center gap-x-6 md:gap-x-12 px-4 my-4">
            <button onClick={handlePreviousDate}>
              <ArrowLeft />
            </button>
            <h2 className="font-semibold text-blue-400 text-lg md:text-2xl text-center min-w-[200px] md:min-w-[400px]">
              {new Date(carePlanDate).toLocaleDateString(t("days.lg"), {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </h2>
            <button className="disabled:opacity-10" onClick={handleNextDate}>
              <ArrowRight />
            </button>
          </div>
          <div className="min-w-full inline-block align-middle">
            <div className="overflow-x-scroll border rounded-lg border-gray-300">
              <table className="min-w-full w-1/2 text-sm rounded-xl text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-white uppercase bg-primary">
                  <tr>
                    <th
                      scope="col"
                      className="sticky bg-primary left-0 z-1 min-w-60 pl-6 pr-3 py-3"
                    >
                      {t("care_plan.table_title")}
                    </th>
                    {Array.from({ length: 24 }).map((_, index) => (
                      <th
                        scope="col"
                        key={index}
                        className="px-6 py-3 font-medium text-white whitespace-nowrap"
                      >
                        {index}h00
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentCarePlan.map((monitoring: any) => (
                    <tr className="bg-white border-b" key={monitoring.id}>
                      <th
                        scope="row"
                        className="sticky left-0 z-20 px-6 py-4 text-white bg-pink-400 text-lg"
                      >
                        <a
                          className="hover:underline"
                          href={
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            monitoring.clickable_url(id)
                          }
                        >
                          {monitoring.name}
                        </a>
                      </th>
                      {Array.from({ length: 24 }).map((_, index) => {
                        const isAlreadyAdded = alreadyAdded(
                          index,
                          carePlanDate,
                          monitorings[monitoring.field_name]
                        );
                        return (
                          <td
                            key={index}
                            className={`px-6 py-4 font-medium text-gray-900 whitespace-nowrap ${!isAlreadyAdded && "hover:bg-gray-100 cursor-pointer"}`}
                            onClick={() =>
                              handleToggleModal(
                                index,
                                monitoring.name,
                                isAlreadyAdded
                              )
                            }
                          >
                            {monitorings[monitoring.field_name]?.find(
                              (m: any) =>
                                new Date(m.dateTime).getHours() === index &&
                                new Date(m.dateTime).toLocaleDateString(
                                  t("days.lg")
                                ) ===
                                  carePlanDate.toLocaleDateString(t("days.lg"))
                            ) ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-green-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            ) : (
                              <div className="opacity-30">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-6 w-6 text-gray-500"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                  />
                                </svg>
                              </div>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      ) : (
        <div className="flex flex-col items-center justify-center h-[50vh]">
          <div className="max-w-lg w-full text-center self-center justify-self-center px-4">
            <h1 className="text-6xl font-bold text-gray-900 mb-4">Oups !</h1>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              {t("care_plan.no_care_plan")}
            </h2>
            <p className="text-gray-600 mb-3">
              {t("care_plan.add_1")}
              <a
                className="underline hover:text-blue-500"
                href={`/consultation/${child.id}`}
                rel="noreferrer"
              >
                {t("care_plan.consultation")}
              </a>
              {t("care_plan.add_2")}
            </p>

            <h2>{t("care_plan.or")}</h2>

            <p className="text-gray-600 my-3">{t("care_plan.add_care_plan")}</p>

            <button
              disabled={true}
              // onClick={() => {}} // Open modal to add new care plan
              className="disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex self-center justify-self-center items-center space-x-2"
            >
              {t("care_plan.add_care_plan_button")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
