/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from "react";
import "./Table.scss";
import { t } from "i18next";
import SearchAutocomplete from "../Autocomplete/Autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCheck,
  faCircleCheck,
  faPenToSquare,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

const TableRow = ({
  columns,
  row,
  onSelect,
  onDelete,
}: {
  columns: any[];
  row: any;
  onSelect: () => void;
  onDelete: () => void;
}) => (
  <tr>
    {columns.map((col, index) => (
      <td key={index}>{row[col.accessor]}</td>
    ))}
    <td className="action-buttons">
      {/* Bouton de modification */}
      <button
        onClick={onSelect}
        type="button"
        className="mt-4 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:blue-emerald-200 flex items-center space-x-2"
      >
        <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
          <FontAwesomeIcon icon={faPenToSquare} style={{ color: "#ffffff" }} />
        </span>
        <span>{t("buttons.edit")}</span>
      </button>
      {/* Bouton de suppression */}
      <button
        onClick={onDelete}
        type="button"
        className="mt-4 bg-gradient-to-r from-red-400 to-red-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-red-200 flex items-center space-x-2"
      >
        <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
          <FontAwesomeIcon icon={faTrashCan} style={{ color: "#ffffff" }} />
        </span>
        <span>{t("buttons.delete")}</span>
      </button>
    </td>
  </tr>
);

interface Suggestions {
  name: string;
  type: string;
}

const Table = ({
  title,
  data,
  columns,
  suggestionsList,
  manifestationList,
  medicineList,
  setTrigger,
  setMedicationName,
  add,
  remove,
  onSave,
}: {
  title: string;
  data: any[];
  columns: any[];
  suggestionsList?: Suggestions[];
  manifestationList?: { id: string; name: string }[];
  medicineList?: any[];
  setTrigger?: Function;
  setMedicationName?: Function;
  add: (item: never) => void;
  remove: (data: any[]) => void;
  onSave?: () => Promise<void>;
}) => {
  const [viewEditItem, setViewEditItem] = useState(false);
  const [viewNewItem, setViewNewItem] = useState(false);
  const [newItem, setNewItem] = useState({});
  const [hasChanged, setHasChanged] = useState(false);
  const canAddItem = useMemo(
    () =>
      Object.keys(newItem).length == columns.length &&
      Object.values(newItem).filter((n) => n != "").length ==
        Object.keys(newItem).length,
    [newItem, columns.length]
  );

  const onConfirm = () => {
    add(newItem as never);
    setNewItem({});
    setViewNewItem(false);
    setHasChanged(true);
  };

  const onChangeData = (type: string, value: string) => {
    setNewItem({ ...newItem, [type]: value });
  };

  return (
    <div className="table-container">
      <h2>{title}</h2>
      {data.length > 0 && (
        <div className="styled-background-table">
          <table className="styled-table">
            <thead>
              <tr>
                {columns.map((col, index) => (
                  <th key={index}>{col.header.toUpperCase()}</th>
                ))}
                <th>{t("new_child.form_label.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  columns={columns}
                  row={{
                    ...row,
                    date: row.date
                      ? new Date(row.date).toISOString().slice(0, 7)
                      : row.date,
                  }}
                  onDelete={() => {
                    remove(data.filter((_, i) => i !== rowIndex));
                    setHasChanged(true);
                  }}
                  onSelect={() => {
                    remove(data.filter((_, i) => i !== rowIndex));
                    setViewEditItem(true);
                    setNewItem(row);
                  }}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
      {viewEditItem && (
        <div className="edit-container">
          {columns.map((col, idx) => (
            <div className="edit-item" key={idx}>
              <p>{col.header}</p>
              {col.component == "input" ? (
                <input
                  type={col.type}
                  onChange={(e) => onChangeData(col.accessor, e.target.value)}
                  placeholder={col.placeholder}
                  // @ts-expect-error error
                  value={newItem[col.accessor]}
                />
              ) : col.type === "autocomplete" ? (
                <SearchAutocomplete
                  // @ts-expect-error error
                  value={newItem[col.accessor]}
                  suggestions={suggestionsList}
                  onInputChange={(input: string) => {
                    onChangeData(col.accessor, input);
                  }}
                  onChange={(input: string) => {
                    onChangeData(col.accessor, input);
                  }}
                />
              ) : col.type === "treatmentList" ? (
                <SearchAutocomplete
                  // @ts-expect-error error
                  value={newItem[col.accessor]}
                  suggestions={suggestionsList}
                  onInputChange={(input) => {
                    setTrigger &&
                      setTrigger((prevState: boolean) => !prevState);
                    setMedicationName && setMedicationName(input);
                    onChangeData(col.accessor, input);
                  }}
                  onChange={(input) => {
                    setMedicationName && setMedicationName("");
                    onChangeData(col.accessor, input);
                  }}
                />
              ) : col.type === "manifestationList" ? (
                <select
                  onChange={(e) => {
                    onChangeData(col.accessor, e.target.value);
                  }}
                  // @ts-expect-error error
                  value={newItem.manifestation}
                >
                  <option value={"chooseOption"} disabled selected>
                    {t("new_child.form-data.placeholder.chooseOption")}
                  </option>
                  {manifestationList?.map((a) => (
                    <option key={a.id} value={a.name}>
                      {a.name}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  onChange={(e) => onChangeData(col.accessor, e.target.value)}
                  // @ts-expect-error error
                  value={newItem[col.accessor]}
                >
                  <option value={"chooseOption"} disabled selected>
                    {t("new_child.form-data.placeholder.chooseOption")}
                  </option>
                  {medicineList?.map((a) => (
                    <option key={a} value={a}>
                      {a}
                    </option>
                  ))}
                </select>
              )}
            </div>
          ))}
          <button
            onClick={() => {
              setViewEditItem(false);
              add(newItem as never);
              setNewItem({});
              setHasChanged(true);
            }}
            type="button"
            className="mt-4 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex self-center space-x-2"
          >
            <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
              <FontAwesomeIcon icon={faCheck} style={{ color: "#ffffff" }} />
            </span>
            <span>{t("buttons.confirm")}</span>
          </button>
        </div>
      )}
      {viewNewItem ? (
        <div className="add-container">
          {columns.map((col, idx) => (
            <div className="add-item" key={idx}>
              <p>{col.header}</p>
              {col.component == "input" ? (
                <input
                  type={col.type}
                  onChange={(e) => onChangeData(col.accessor, e.target.value)}
                  placeholder={col.placeholder}
                />
              ) : col.type === "autocomplete" ? (
                <SearchAutocomplete
                  // @ts-expect-error error
                  value={newItem[col.accessor]}
                  suggestions={suggestionsList}
                  onInputChange={(input: string) => {
                    onChangeData(col.accessor, input);
                  }}
                  onChange={(input: string) => {
                    onChangeData(col.accessor, input);
                  }}
                />
              ) : col.type === "treatmentList" ? (
                <SearchAutocomplete
                  // @ts-expect-error error
                  value={newItem[col.accessor]}
                  suggestions={suggestionsList}
                  onInputChange={(input) => {
                    setTrigger &&
                      setTrigger((prevState: boolean) => !prevState);
                    setMedicationName && setMedicationName(input);
                    onChangeData(col.accessor, input);
                  }}
                  onChange={(input) => {
                    setMedicationName && setMedicationName("");
                    onChangeData(col.accessor, input);
                  }}
                />
              ) : col.type === "manifestationList" ? (
                <select
                  onChange={(e) => {
                    onChangeData(col.accessor, e.target.value);
                  }}
                >
                  <option value={"chooseOption"} disabled selected>
                    {t("new_child.form-data.placeholder.chooseOption")}
                  </option>
                  {manifestationList?.map((a) => (
                    <option key={a.id} value={a.name}>
                      {a.name}
                    </option>
                  ))}
                </select>
              ) : (
                <select
                  onChange={(e) => onChangeData(col.accessor, e.target.value)}
                >
                  <option value={"chooseOption"} disabled selected>
                    {t("new_child.form-data.placeholder.chooseOption")}
                  </option>
                  {medicineList?.map((a) => (
                    <option key={a} value={a}>
                      {a}
                    </option>
                  ))}
                </select>
              )}
            </div>
          ))}
          <div className="buttons-block flex flex-row self-center">
            <button
              onClick={onConfirm}
              disabled={!canAddItem}
              type="button"
              className={`bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform ${
                !canAddItem
                  ? "opacity-50 cursor-not-allowed bg-gray-400" // Style pour état disabled
                  : "hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200"
              } flex items-center space-x-2`}
            >
              <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
              </span>
              <span>{t("buttons.add")}</span>
            </button>
            {viewNewItem && (
              <button
                onClick={() => setViewNewItem(false)}
                type="button"
                className="bg-gradient-to-r from-red-400 to-red-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-red-200 flex items-center space-x-2"
              >
                <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                  <FontAwesomeIcon icon={faBan} style={{ color: "#ffffff" }} />
                </span>
                <span>{t("buttons.cancel")}</span>
              </button>
            )}
          </div>
        </div>
      ) : !viewEditItem ? (
        <div className="flex items-center gap-3">
          <button
            onClick={() => setViewNewItem(true)}
            type="button"
            className="mt-4 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
          >
            <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
              <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
            </span>
            <span>{t("buttons.add")}</span>
          </button>
          {onSave && hasChanged && (
            <button
              onClick={onSave}
              type="button"
              className="mt-4 bg-gradient-to-r from-[#4CAF50] via-[#66BB6A] to-[#81C784] text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-[#A5D6A7] flex items-center space-x-2"
            >
              <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#ffffff" }}
                />
              </span>
              <span>{t("buttons.update")}</span>
            </button>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Table;
