/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import ProfilePic0 from "../assets/profilPic/0.png";
import ProfilePic1 from "../assets/profilPic/1.png";
import ProfilePic2 from "../assets/profilPic/2.png";
import ProfilePic3 from "../assets/profilPic/3.png";
import ProfilePic4 from "../assets/profilPic/4.png";
import ProfilePic5 from "../assets/profilPic/5.png";
import ProfilePic6 from "../assets/profilPic/6.png";
import ProfilePic7 from "../assets/profilPic/7.png";
import ProfilePic8 from "../assets/profilPic/8.png";
import ProfilePic9 from "../assets/profilPic/9.png";
import ProfilePic10 from "../assets/profilPic/10.png";
import ProfilePic11 from "../assets/profilPic/11.png";
import ProfilePic12 from "../assets/profilPic/12.png";
import Pavillon from "../assets/logo/logo_pavillon.png";

import MontserratFontRegular from "../assets/fonts/Montserrat/static/Montserrat-Regular.ttf";
import MontserratFontBold from "../assets/fonts/Montserrat/static/Montserrat-Bold.ttf";
import MontserratFontSemibold from "../assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf";
import MontserratFontItalic from "../assets/fonts/Montserrat/static/Montserrat-Italic.ttf";
import { t } from "i18next";
import { manifestationMatchingList } from "./Monitoring/constants";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: MontserratFontRegular,
    },
    {
      src: MontserratFontBold,
      fontWeight: "bold",
    },
    {
      src: MontserratFontSemibold,
      fontWeight: "semibold",
    },
    {
      src: MontserratFontItalic,
      fontStyle: "italic",
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    rowGap: 16,
    paddingHorizontal: 30,
    fontSize: 12,
    fontFamily: "Montserrat",
    paddingVertical: 40,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  header_section_title: {},
  header_title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#FF6B7E",
  },
  header_infos_section: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: 15,
  },
  header_infos: {
    flexDirection: "column",
    rowGap: 15,
  },
  header_section_pp: {
    flexDirection: "column",
    alignItems: "center",
  },
  header_pp: {
    width: 75,
    height: "auto",
  },
  pavillon_logo: {
    width: 50,
    height: "auto",
    marginTop: 10,
  },
  section_title: {
    textDecoration: "underline",
    color: "#FF6B7E",
    fontWeight: "semibold",
    fontSize: 16,
  },
  questions_section: {
    fontWeight: "semibold",
    fontSize: 14,
  },
  questions_section_questions: {
    marginTop: 15,
    flexDirection: "column",
    rowGap: 10,
  },
  background_section: {},
  table: {
    marginTop: 15,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  table_header_row: {
    marginBottom: 10,
    columnGap: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  table_header_cell: {
    paddingVertical: 5,
    paddingLeft: 10,
    fontWeight: "bold",
    border: "1px solid #55A47E",
  },
  table_body_row: {
    columnGap: 10,
    flexDirection: "row",
    alignItems: "stretch",
  },
  table_body_cell: {
    fontSize: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
    border: "1px solid #000",
  },
  treatment_section: {},
  allergies_section: {},
  allergies_table: {},
  care_plan_section: {},
  care_plan_table: {},
  title_h1: {},
  title_h2: {},
  text_em: {
    fontWeight: "bold",
    color: "#55A47E",
  },
});

interface AboutChildPdfProps {
  childInfos: any;
  childBackgrounds: any;
  anomalies: any;
}

const profilesPics = [
  ProfilePic0,
  ProfilePic1,
  ProfilePic2,
  ProfilePic3,
  ProfilePic4,
  ProfilePic5,
  ProfilePic6,
  ProfilePic7,
  ProfilePic8,
  ProfilePic9,
  ProfilePic10,
  ProfilePic11,
  ProfilePic12,
];

const getAge = (dateString: string) => {
  const today = new Date();
  const birthDate = new Date(dateString);

  const years = today.getFullYear() - birthDate.getFullYear();
  const months =
    years * 12 +
    (today.getMonth() - birthDate.getMonth()) -
    (today.getDate() < birthDate.getDate() ? 1 : 0);

  if (years < 2) {
    return `${months} mois`;
  }

  if (months < 1) {
    return `< 1 mois`;
  }

  return `${years} ${years > 1 ? "ans" : "an"}`;
};

// Create Document Component
export const AboutChildPdf = ({
  childInfos,
  childBackgrounds,
  anomalies,
}: AboutChildPdfProps) => {
  if (!childInfos || !childBackgrounds || !anomalies) return null;

  const getTreatmentFrequency = (
    frequency: string,
    interval?: string,
    days?: string[],
    dailyFrequency?: string
  ) => {
    switch (frequency) {
      case "DAILY":
        return t("children.frequency.daily", { nb: dailyFrequency });
      case "WEEKLY":
        return (
          t("determinants.the") +
          " " +
          days?.map((d: string) => t(`days.${d}`).toLowerCase()).join(", ")
        );
      case "EVERY_DAY":
        return t("new_child.form_label.every_day");
      case "INTERVAL":
        return t("children.frequency.interval", { nb: interval });
      default:
        "Non défini";
    }
  };

  const childBackgroundsMedicationMonitorings = (
    <View style={{ ...styles.background_section }}>
      <Text style={styles.section_title}>
        Liste des antécédents (maladies ou opérations) :
      </Text>
      <View style={styles.table}>
        <View style={styles.table_header_row}>
          <Text style={{ ...styles.table_header_cell, width: "20%" }}>
            Date
          </Text>
          <Text style={{ ...styles.table_header_cell, width: "30%" }}>
            Antécédent
          </Text>
          <Text style={{ ...styles.table_header_cell, width: "50%" }}>
            Commentaires
          </Text>
        </View>
        {childBackgrounds.medicalBackgrounds.map((bg: any, index: number) => (
          <View style={styles.table_body_row} key={index}>
            <Text style={{ ...styles.table_body_cell, width: "20%" }}>
              {new Date(bg.date).toLocaleDateString(t("days.lg"), {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            </Text>
            <Text style={{ ...styles.table_body_cell, width: "30%" }}>
              {bg.name}
            </Text>
            <Text style={{ ...styles.table_body_cell, width: "50%" }}>
              {bg.comment ?? "-"}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );

  const childBackgroundsAllergies = (
    <View style={styles.allergies_section}>
      <Text style={styles.section_title}>Liste des allergies :</Text>
      <View style={styles.table}>
        <View style={styles.table_header_row}>
          <Text style={{ ...styles.table_header_cell, width: "20%" }}>
            Date
          </Text>
          <Text style={{ ...styles.table_header_cell, width: "30%" }}>
            Allergie
          </Text>
          <Text style={{ ...styles.table_header_cell, width: "50%" }}>
            Symptômes
          </Text>
        </View>
        {childBackgrounds.allergies.map((al: any, index: number) => (
          <View style={styles.table_body_row} key={index}>
            <Text style={{ ...styles.table_body_cell, width: "20%" }}>
              {new Date(al.date).toLocaleDateString(t("days.lg"), {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            </Text>
            <Text style={{ ...styles.table_body_cell, width: "30%" }}>
              {al.allergy.name}
            </Text>
            <Text style={{ ...styles.table_body_cell, width: "50%" }}>
              {
                (
                  manifestationMatchingList.find(
                    (m) => m.id === al.manifestation
                  ) ?? { label: "" }
                ).name
              }
            </Text>
          </View>
        ))}
      </View>
    </View>
  );

  const childBackgroundsTreatments = (
    <View style={{ ...styles.background_section }}>
      <Text style={styles.section_title}>{t("children.treatments_list")}</Text>
      <View style={styles.table}>
        <View style={styles.table_header_row}>
          <Text style={{ ...styles.table_header_cell, width: "30%" }}>
            {t("consultation.table_header.medication")}
          </Text>
          <Text style={{ ...styles.table_header_cell, width: "20%" }}>
            {t("consultation.table_header.dosage")}
          </Text>
          <Text style={{ ...styles.table_header_cell, width: "50%" }}>
            {t("consultation.table_header.frequency")}
          </Text>
        </View>
        {childBackgrounds.medicationTreatments.map(
          (treatment: any, index: number) => (
            <View style={styles.table_body_row} key={index}>
              <Text style={{ ...styles.table_body_cell, width: "30%" }}>
                {treatment.medication}
              </Text>
              <Text style={{ ...styles.table_body_cell, width: "20%" }}>
                {treatment.dosageAmount +
                  " " +
                  (treatment.dosageUnit === "PILL"
                    ? t("new_child.form_label.pill", {
                        count: treatment.dosageAmount,
                      })
                    : treatment.dosageUnit
                  ).toLowerCase()}
              </Text>
              <Text style={{ ...styles.table_body_cell, width: "50%" }}>
                {getTreatmentFrequency(
                  treatment.frequencyType,
                  treatment.interval,
                  treatment.days,
                  treatment.dailyFrequency
                )}
              </Text>
            </View>
          )
        )}
      </View>
    </View>
  );

  const childAnomalies = (
    <View style={{ ...styles.background_section }}>
      <Text style={styles.section_title}>{t("children.anomalies_list")}</Text>
      <View style={styles.table}>
        <View style={styles.table_header_row}>
          <Text style={{ ...styles.table_header_cell, width: "30%" }}>
            Date
          </Text>
          <Text style={{ ...styles.table_header_cell, width: "70%" }}>
            Message
          </Text>
        </View>
        {anomalies.map((anomaly: any, index: number) => (
          <View style={styles.table_body_row} key={index}>
            <Text style={{ ...styles.table_body_cell, width: "30%" }}>
              {new Date(anomaly.sentAt).toLocaleDateString(t("days.lg"), {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            </Text>
            <Text style={{ ...styles.table_body_cell, width: "70%" }}>
              {anomaly.message}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.header_section_title}>
            <Image src={Pavillon} style={styles.pavillon_logo} />
            <Text style={styles.header_title}>Je dis quoi au docteur ?</Text>
          </View>
          <View style={styles.header_infos_section}>
            <View style={styles.header_infos}>
              <Text>
                <Text style={styles.text_em}>Âge : </Text>
                {getAge(childInfos.child.birthdate)}
              </Text>
              <Text>
                <Text>
                  {"("}
                  {new Date(childInfos.child.birthdate).toLocaleDateString(
                    t("days.lg"),
                    {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }
                  )}
                  {")"}
                </Text>
              </Text>
              <Text>
                <Text style={styles.text_em}>Poids : </Text>
                {childInfos.weight.value} kg
              </Text>
              <Text>
                <Text style={styles.text_em}>Taille : </Text>
                {childInfos.size.value} cm
              </Text>
            </View>
            <View style={styles.header_section_pp}>
              <Image
                src={profilesPics[childInfos.child.image_id]}
                style={styles.header_pp}
              />
              <Text style={{ fontWeight: "bold" }}>
                {childInfos.child.firstname}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.questions_section}>
          <Text style={styles.section_title}>Les questions à vous poser :</Text>
          <View style={styles.questions_section_questions}>
            <Text>Pourquoi consultez-vous un docteur ?</Text>
            <Text>Depuis quand c'est présent ?</Text>
            <Text>Quel traitement / soin avez-vous fait ?</Text>
            <Text>Qu'est ce qui vous inquiète ?</Text>
          </View>
        </View>
        <View style={styles.treatment_section}>
          <Text style={styles.section_title}>
            Dernières prises de médicaments :
          </Text>
          <View style={styles.table}>
            <View style={styles.table_header_row}>
              <Text style={{ ...styles.table_header_cell, width: "110px" }}>
                Date
              </Text>
              <Text style={{ ...styles.table_header_cell, width: "250px" }}>
                Médicament
              </Text>
              <Text style={{ ...styles.table_header_cell, width: "175px" }}>
                Mode de prise
              </Text>
              <Text style={{ ...styles.table_header_cell, width: "75px" }}>
                Dose
              </Text>
            </View>
            {childBackgrounds.medicationMonitorings.map(
              (tr: any, index: number) => (
                <View style={styles.table_body_row} key={index}>
                  <Text style={{ ...styles.table_body_cell, width: "110px" }}>
                    {tr.dateTime.length
                      ? new Date(tr.dateTime).toLocaleDateString(t("days.lg"), {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })
                      : null}{" "}
                    {tr.dateTime.length
                      ? new Date(tr.dateTime).toLocaleTimeString(t("days.lg"), {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : null}
                  </Text>
                  <Text style={{ ...styles.table_body_cell, width: "250px" }}>
                    {tr.medication.length >= 32
                      ? tr.medication.substring(0, 32) + "..."
                      : tr.medication}
                  </Text>
                  <Text style={{ ...styles.table_body_cell, width: "175px" }}>
                    {tr.taking}
                  </Text>
                  <Text style={{ ...styles.table_body_cell, width: "75px" }}>
                    {tr.dosage} {tr.dosage ? "mg" : null}
                  </Text>
                </View>
              )
            )}
          </View>
        </View>
        {childBackgrounds.medicationMonitorings.length +
          childBackgrounds.medicalBackgrounds.length <
          14 && childBackgroundsMedicationMonitorings}
        {childBackgrounds.medicationMonitorings.length +
          childBackgrounds.medicalBackgrounds.length +
          childBackgrounds.allergies.length <
          9 && childBackgroundsAllergies}
        {childBackgrounds.medicationMonitorings.length +
          childBackgrounds.medicalBackgrounds.length +
          childBackgrounds.allergies.length +
          childBackgrounds.medicationTreatments.length <
          4 && childBackgroundsTreatments}
      </Page>
      <Page size="A4" style={styles.page}>
        {childBackgrounds.medicationMonitorings.length +
          childBackgrounds.medicalBackgrounds.length >=
          14 && childBackgroundsMedicationMonitorings}
        {childBackgrounds.medicationMonitorings.length +
          childBackgrounds.medicalBackgrounds.length +
          childBackgrounds.allergies.length >=
          9 && childBackgroundsAllergies}
        {childBackgrounds.medicationMonitorings.length +
          childBackgrounds.medicalBackgrounds.length +
          childBackgrounds.allergies.length +
          childBackgrounds.medicationTreatments.length >=
          4 && childBackgroundsTreatments}
        {childAnomalies}
      </Page>
    </Document>
  );
};
