import React, { useCallback, useEffect, useState } from "react";
import Logo from "../assets/logo/logo-white.png";
import Profil from "../assets/icons/profil-white.png";
import Home from "../assets/icons/home-white.png";
import { useTranslation } from "react-i18next";
import FrenchFlag from "../assets/flags/fr.svg";
import EnglishFlag from "../assets/flags/en.svg";
import { Bell, X } from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import { fr, enUS } from "date-fns/locale";
import axios from "../lib/config/axios";
import { useAuth } from "../hooks/useAuth";

type Notification = {
  id: number;
  childId: number;
  message: string;
  anomaly: string;
  severity: "LOW" | "MEDIUM" | "HIGH";
  sentAt: string;
  isRead: boolean;
  createdAt: string;
  updatedAt: string;
};

// @ts-expect-error error
const isNumber = (str) => !isNaN(str) && str.trim() !== "";

export const NavBar = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const currentId = window.location.pathname.split("/").slice(-1)[0];

  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("locale") || "fr"
  );

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);

  const formatRelativeTime = (dateString: string) => {
    const date = new Date(dateString);
    return formatDistanceToNow(date, {
      locale: currentLanguage === "fr" ? fr : enUS,
      addSuffix: true,
    });
  };

  const switchLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "fr" : "en";
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
    localStorage.setItem("locale", newLanguage);
  };

  if (
    window.location.pathname === "/login" ||
    window.location.pathname === "/register"
  ) {
    return null;
  }

  // Function to check if there's any anomaly
  const checkAnomalies = async () => {
    try {
      await axios.post(
        `/anomaly-detection/${currentId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
    } catch (error) {
      console.error("Error calling /anomaly-detection", error);
    }
  };

  // Function to fetch notifications
  const fetchNotifications = async () => {
    try {
      const res = await axios.get(`/children/${currentId}/notifications`, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      });

      if (res.status === 200) {
        const sortedNotifications = res.data.sort(
          (a: Notification, b: Notification) =>
            new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime()
        );
        setNotifications(sortedNotifications);
        const unreadCount = res.data.filter(
          (notification: Notification) => !notification.isRead
        ).length;
        setUnreadNotifications(unreadCount);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  // Polling mechanism
  useEffect(() => {
    if (!isNumber(currentId)) return;

    fetchNotifications();

    const interval = setInterval(async () => {
      await checkAnomalies();
      await fetchNotifications();
    }, 10000);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [10000, currentId]);

  const handleShowNotifications = useCallback(async () => {
    let isNotificationsOpened = false;
    if (showNotifications) {
      isNotificationsOpened = true;
    }
    setShowNotifications((prevState) => !prevState);

    if (isNotificationsOpened) {
      for (const notification of notifications) {
        try {
          await axios.patch(
            `/children/${currentId}/notifications/${notification.id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          setNotifications((prevNotifications) =>
            prevNotifications.map((prevNotification) =>
              prevNotification.id === notification.id
                ? { ...prevNotification, isRead: true }
                : prevNotification
            )
          );
        } catch (error) {
          console.error(`Error updating item ${notification.id}:`, error);
        }
      }
    }

    setUnreadNotifications(0);
  }, [notifications]);

  return (
    <div className="md:h-16 h-12 px-4 w-full bg-blue-300 shadow-lg flex flex-row justify-between items-center">
      <div className="ml-3 flex items-center">
        <a href="/">
          <img src={Logo} alt="BASSIST' Logo" className="md:w-32 w-24" />
        </a>
      </div>

      <div className="flex flex-row items-center gap-8 mr-4">
        <a
          href="/children"
          className="text-white flex flex-row items-center gap-2 hover:text-blue-500 transition duration-300 transform hover:scale-105"
        >
          <img src={Home} alt="Home" className="md:w-8 w-6" />
          <span className="hidden lg:block font-semibold">
            {t("navbar.menu")}
          </span>
        </a>

        <a
          href="/parent-profile"
          className="text-white flex flex-row items-center gap-2 hover:text-blue-500 transition duration-300 transform hover:scale-105"
        >
          <img src={Profil} alt="Profil" className="md:w-8 w-6" />
          <span className="hidden lg:block font-semibold">
            {t("navbar.profile")}
          </span>
        </a>

        <button
          onClick={switchLanguage}
          className="flex items-center justify-center md:w-10 w-8 h-8 bg-white rounded-full shadow-md hover:shadow-lg transition-all duration-300 transform hover:scale-110"
        >
          <img
            src={currentLanguage === "fr" ? FrenchFlag : EnglishFlag}
            alt={currentLanguage === "fr" ? "French Flag" : "English Flag"}
            className="w-5"
          />
        </button>

        <button
          onClick={handleShowNotifications}
          className=" relative bg-white text-blue-300 font-semibold md:text-lg py-2 px-2 rounded-full shadow-md transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-4 focus:ring-blue-200"
        >
          <Bell size={20} />
          {unreadNotifications > 0 && (
            <div className="absolute -top-1 -right-1 text-sm bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center">
              {unreadNotifications}
            </div>
          )}
        </button>
      </div>

      {showNotifications && (
        <div className="absolute top-12 right-1 md:top-12 md:right-4 bg-white w-80 md:w-96 p-4 z-50 border-2 border-blue-300 rounded-xl shadow-lg overflow-auto max-h-[50%]">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-semibold">
              {t("navbar.notifications")}
            </h3>
            <button onClick={handleShowNotifications}>
              <X size={20} />
            </button>
          </div>
          {notifications.length > 0 ? (
            <ul className="space-y-2">
              {notifications.map((notification) => (
                <li
                  key={notification.id}
                  className="flex items-center gap-4 bg-gray-100 p-3 rounded-lg border"
                >
                  {!notification.isRead && (
                    <span className="w-3 h-3 bg-red-500 rounded-full flex-shrink-0"></span>
                  )}
                  <div className="flex-1">
                    <p className="font-semibold">{notification.message}</p>
                    <p className="text-sm text-gray-600">
                      {notification.anomaly}
                    </p>
                    <p className="text-xs text-gray-500">
                      {formatRelativeTime(notification.sentAt)}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">{t("navbar.no_notifications")}</p>
          )}
        </div>
      )}
    </div>
  );
};
