import React, { useState } from "react";
import Logo from "../assets/logo/logo_bassist.png";
import FamilyIllustration from "../assets/illustrations/family.png";
import PavillonLogo from "../assets/logo/logo_pavillon.png";
import axios from "../lib/config/axios";
import { useSnackbar } from "notistack";
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { FaHandHoldingHeart, FaUserPlus } from "react-icons/fa";

export const Login = () => {
  const { login, user } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  if (user) {
    window.location.href = "/children";
  }

  const handleLogin = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!form.email?.length || !form.password?.length) return;
    axios
      .post("/users/login", form)
      .then((response) => {
        if (response.status !== 200) throw new Error();
        login(response.data.access_token);
      })
      .catch(() => {
        enqueueSnackbar(t("snackbar.bad_credentials"), {
          autoHideDuration: 2000,
          variant: "error",
        });
      });
  };

  return (
    <div className="flex flex-col justify-center min-h-screen bg-blue-100">
      <div className="relative flex flex-row justify-center mt-12">
        <div className="hidden md:flex flex-col items-center justify-center w-1/2">
          <img
            src={FamilyIllustration}
            alt="Family Illustration"
            className="w-2/3 mb-6"
          />
          <img
            src={PavillonLogo}
            alt="Logo Pavillon de la Mutualité"
            className="w-1/3"
          />
        </div>
        <div className="w-full md:w-1/2 flex justify-center">
          <div className="w-11/12 max-w-lg flex flex-col items-center bg-white p-8 rounded-2xl shadow-lg">
            <img src={Logo} alt="BA'SSIST Logo" className="w-1/2 mb-4" />
            <p className="text-xl font-bold text-gray-700 mb-6">
              {t("login.already_account")}
            </p>
            <form
              className="flex flex-col w-full items-center gap-6"
              onSubmit={handleLogin}
            >
              <input
                type="email"
                onChange={(e) => setForm({ ...form, email: e.target.value })}
                className="w-4/5 border border-gray-300 py-2 px-4 rounded-lg shadow-sm focus:ring-1 focus:ring-blue-500 focus:outline-none"
                placeholder={t("login.email")}
              />
              <input
                type="password"
                onChange={(e) =>
                  setForm({
                    ...form,
                    password: e.target.value,
                  })
                }
                className="w-4/5 border border-gray-300 py-2 px-4 rounded-lg shadow-sm focus:ring-1 focus:ring-blue-500 focus:outline-none"
                placeholder={t("login.password")}
              />
              <div className="flex items-center w-4/5">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-blue-500 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 text-sm text-gray-600"
                >
                  {t("login.remember_me")}
                </label>
              </div>
              <button
                type="submit"
                className="mt-4 w-4/5 bg-gradient-to-r from-blue-500 to-blue-400 text-center text-white font-medium py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 space-x-2"
              >
                <span>{t("titles.login")}</span>
              </button>
            </form>
            <a
              href="#"
              className="text-blue-500 font-semibold mt-4 hover:underline"
            >
              {t("login.password_forgotten")}
            </a>
            <hr className="w-4/5 my-4 border-gray-300" />
            <div className="flex flex-col w-full items-center space-y-4 mt-4">
              {/* Première ligne */}
              <div className="flex w-full justify-around">
                <div className="flex w-full flex-col items-center w-40 p-4 bg-white rounded-lg shadow-md">
                  {/* Logo */}
                  <FaUserPlus className="text-blue-500 mb-2 text-4xl" />
                  {/* Texte */}
                  <p className="text-md font-semibold text-gray-700">
                    {t("login.new_on_the_app")}
                  </p>
                  {/* Lien */}
                  <a
                    href="/register"
                    className="text-blue-500 font-bold mt-2 hover:underline"
                  >
                    {t("login.sign_up")}
                  </a>
                </div>
              </div>

              {/* Deuxième ligne */}
              <div className="flex w-full justify-around">
                <div className="flex w-full flex-col items-center w-40 p-4 bg-gradient-to-r from-blue-300 to-blue-500 rounded-lg shadow-lg transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-2xl">
                  <FaHandHoldingHeart className="text-white mb-2 text-3xl" />
                  <p className="text-md font-bold text-white hover:text-gray-100 transition-colors duration-200">
                    {t("login.access_aidant")}
                  </p>
                  <a
                    href="/#"
                    className="text-white font-bold text-sm mt-2 hover:underline"
                  >
                    {t("login.details")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
