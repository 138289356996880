/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from "react";
import "./Table.scss";
import { t } from "i18next";
import SearchAutocomplete from "../Autocomplete/Autocomplete";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCheck,
  faCircleCheck,
  faPenToSquare,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

const UNITS = ["mg", "g", "ml", "pill"];

const PERIODS = [
  { id: "DAILY", name: "new_child.form_label.daily" },
  { id: "EVERY_DAY", name: "new_child.form_label.every_day" },
  { id: "WEEKLY", name: "new_child.form_label.weekly" },
  { id: "INTERVAL", name: "new_child.form_label.interval" },
];

const TableRow = ({
  columns,
  row,
  onSelect,
  onDelete,
}: {
  columns: any[];
  row: any;
  onSelect: () => void;
  onDelete: () => void;
}) => (
  <tr>
    {columns.map((col, index) => (
      <td
        key={index}
        style={{
          maxWidth: "20ch",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {col.accessor === "frequencyType"
          ? t(PERIODS.find((p) => p.id === row[col.accessor])?.name || "")
          : col.accessor === "days"
            ? (row[col.accessor] || [])
                .map((day: string) => t(`days.${day}`))
                .join(", ")
            : row[col.accessor] === "pill"
              ? t("new_child.form_label.pill", { count: 1 })
              : row[col.accessor]}
      </td>
    ))}
    <td className="action-buttons">
      {/* Bouton de modification */}
      <button
        onClick={onSelect}
        type="button"
        className="mt-4 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:blue-emerald-200 flex items-center space-x-2"
      >
        <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
          <FontAwesomeIcon icon={faPenToSquare} style={{ color: "#ffffff" }} />
        </span>
        <span>{t("buttons.edit")}</span>
      </button>
      {/* Bouton de suppression */}
      <button
        onClick={onDelete}
        type="button"
        className="mt-4 bg-gradient-to-r from-red-400 to-red-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-red-200 flex items-center space-x-2"
      >
        <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
          <FontAwesomeIcon icon={faTrashCan} style={{ color: "#ffffff" }} />
        </span>
        <span>{t("buttons.delete")}</span>
      </button>
    </td>
  </tr>
);

interface Suggestions {
  name: string;
  type: string;
}

type DayOptions = {
  value: string;
  label: string;
};

const TableMedicationTreatment = ({
  title,
  data,
  medicationName,
  setTrigger,
  setMedicationName,
  suggestionsList,
  add,
  remove,
  onSave,
  withDates = false,
}: {
  title: string;
  data: any[];
  medicationName: string;
  setTrigger: Function;
  setMedicationName: Function;
  suggestionsList?: Suggestions[];
  add: (item: never) => void;
  remove: (data: any[]) => void;
  onSave?: () => Promise<void>;
  withDates?: boolean;
}) => {
  const [viewEditItem, setViewEditItem] = useState(false);
  const [viewNewItem, setViewNewItem] = useState(false);
  const [newItem, setNewItem] = useState({});
  const [hasChanged, setHasChanged] = useState(false);

  const canAddItem = useMemo(() => {
    const firstcheck =
      // @ts-expect-error error
      newItem["medication"] &&
      // @ts-expect-error error
      newItem["dosageAmount"] &&
      // @ts-expect-error error
      newItem["dosageUnit"] &&
      // @ts-expect-error error
      newItem["frequencyType"];
    // @ts-expect-error error
    if (newItem["frequencyType"] === "DAILY") {
      // @ts-expect-error error
      return firstcheck && newItem["dailyFrequency"];
      // @ts-expect-error error
    } else if (newItem["frequencyType"] === "WEEKLY") {
      // @ts-expect-error error
      return firstcheck && newItem["days"] && newItem["days"].length > 0;
      // @ts-expect-error error
    } else if (newItem["frequencyType"] === "INTERVAL") {
      // @ts-expect-error error
      return firstcheck && newItem["interval"];
    } else {
      return firstcheck;
    }
  }, [newItem]);

  const onConfirm = () => {
    add(newItem as never);
    setNewItem({});
    setViewNewItem(false);
    setHasChanged(true);
  };

  const onChangeData = (type: string, value: string | string[]) => {
    if (type === "frequencyType") {
      setNewItem({
        ...newItem,
        days: null,
        dailyFrequency: null,
        interval: null,
        [type]: value,
      });
    } else {
      setNewItem({ ...newItem, [type]: value });
    }
  };

  const daysOptions: readonly DayOptions[] = [
    {
      value: "monday",
      label: t("days.monday"),
    },
    {
      value: "tuesday",
      label: t("days.tuesday"),
    },
    {
      value: "wednesday",
      label: t("days.wednesday"),
    },
    {
      value: "thursday",
      label: t("days.thursday"),
    },
    {
      value: "friday",
      label: t("days.friday"),
    },
    {
      value: "saturday",
      label: t("days.saturday"),
    },
    {
      value: "sunday",
      label: t("days.sunday"),
    },
  ];

  const medicationColumns = useMemo(
    () => [
      {
        header: t("new_child.form_label.medication"),
        accessor: "medication",
        type: "treatmentList",
        component: "select",
        placeholder: t("consultation.table_header.medication"),
      },
      {
        header: t("new_child.form_label.quantity"),
        accessor: "dosageAmount",
        type: "number",
        component: "input",
        placeholder: "200",
      },
      {
        header: t("new_child.form_label.unit"),
        accessor: "dosageUnit",
        type: "dosageUnit",
        component: "select",
        placeholder: t("new_child.form_label.unit"),
      },
      {
        header: t("new_child.form_label.frequency"),
        accessor: "frequencyType",
        type: "frequencyType",
        component: "select",
        placeholder: t("new_child.form_label.frequency"),
      },
      {
        header: t("new_child.form_label.daily_frequency"),
        accessor: "dailyFrequency",
        type: "number",
        component: "input",
        placeholder: t("new_child.form-data.placeholder.medicine"),
      },
      {
        header: t("new_child.form_label.days_selection"),
        accessor: "days",
        type: "multiSelect",
        component: "multiSelect",
        placeholder: t("new_child.form-data.placeholder.medicine"),
      },
      {
        header: t("new_child.form_label.interval_frequency"),
        accessor: "interval",
        type: "number",
        component: "input",
        placeholder: t("new_child.form_label.interval_days", {
          nbDays: 2,
        }),
      },
    ],
    []
  );

  return (
    <div className="table-container">
      <h2>{title}</h2>
      {data.length > 0 && (
        <div className="styled-background-table">
          <table className="styled-table">
            <thead>
              <tr>
                {medicationColumns.map(
                  (col, index) =>
                    data.find((d) => d[col.accessor]) && (
                      <th key={index}>{col.header.toUpperCase()}</th>
                    )
                )}
                <th>{t("new_child.form_label.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  columns={medicationColumns.filter((col) =>
                    data.find((d) => d[col.accessor])
                  )}
                  row={row}
                  onDelete={() => {
                    remove(data.filter((_, i) => i !== rowIndex));
                    setHasChanged(true);
                  }}
                  onSelect={() => {
                    remove(data.filter((_, i) => i !== rowIndex));
                    setViewEditItem(true);
                    setNewItem(row);
                  }}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
      {(viewNewItem || viewEditItem) && (
        <div className="add-container">
          <div className="add-item">
            <p>{t("new_child.form_label.medication")}</p>
            <SearchAutocomplete
              // @ts-expect-error error
              value={newItem["medication"]}
              placeholder={t("consultation.table_header.medication")}
              suggestions={suggestionsList}
              onInputChange={(input) => {
                setTrigger((prevState: boolean) => !prevState);
                setMedicationName(input);
                onChangeData("medication", medicationName);
              }}
              onChange={(input) => {
                setMedicationName("");
                onChangeData("medication", input);
              }}
            />
          </div>

          <div className="add-item">
            <p>{t("new_child.form_label.quantity")}</p>
            <input
              type="number"
              // @ts-expect-error error
              value={newItem["dosageAmount"]}
              onChange={(e) => onChangeData("dosageAmount", e.target.value)}
              placeholder="200"
            />
          </div>

          <div className="add-item">
            <p>{t("new_child.form_label.unit")}</p>
            <select
              onChange={(e) => {
                onChangeData("dosageUnit", e.target.value);
              }}
              value={
                // @ts-expect-error error
                newItem["dosageUnit"]
                  ? // @ts-expect-error error
                    newItem["dosageUnit"].toLowerCase()
                  : // @ts-expect-error error
                    newItem["dosageUnit"]
              }
              defaultValue={"chooseOption"}
            >
              <option value={"chooseOption"} disabled>
                {t("new_child.form_label.unit")}
              </option>
              {UNITS.map((a, index) => (
                <option key={index} value={a}>
                  {a === "pill"
                    ? t("new_child.form_label.pill", {
                        count: 1,
                      })
                    : a}
                </option>
              ))}
            </select>
          </div>

          <div className="add-item">
            <p>{t("new_child.form_label.frequency")}</p>
            <select
              onChange={(e) => {
                onChangeData("frequencyType", e.target.value);
              }}
              // @ts-expect-error error
              value={newItem["frequencyType"]}
              defaultValue={"chooseOption"}
            >
              <option value={"chooseOption"} disabled>
                {t("new_child.form_label.frequency")}
              </option>
              {PERIODS.map((a, index) => (
                <option key={index} value={a.id}>
                  {t(a.name)}
                </option>
              ))}
            </select>
          </div>

          {
            // @ts-expect-error error
            newItem["frequencyType"] === "DAILY" && (
              <div className="add-item">
                <p>{t("new_child.form_label.daily_frequency")}</p>
                <input
                  type="number"
                  // @ts-expect-error error
                  value={newItem["dailyFrequency"]}
                  onChange={(e) =>
                    onChangeData("dailyFrequency", e.target.value)
                  }
                  placeholder={t("new_child.form-data.placeholder.medicine")}
                />
              </div>
            )
          }

          {
            // @ts-expect-error error
            newItem["frequencyType"] === "WEEKLY" && (
              <div className="add-item">
                <p>{t("new_child.form_label.days_selection")}</p>
                <Select
                  className="min-w-96"
                  name="days"
                  options={daysOptions}
                  isMulti
                  // @ts-expect-error error
                  value={newItem["days"]?.map((d) => ({
                    value: d,
                    label: t(`days.${d}`),
                  }))}
                  onChange={(selected) => {
                    const selectedDays = selected.map((s) => s.value);
                    onChangeData("days", selectedDays);
                  }}
                />
              </div>
            )
          }

          {
            // @ts-expect-error error
            newItem["frequencyType"] === "INTERVAL" && (
              <div className="add-item">
                <p>{t("new_child.form_label.interval_frequency")}</p>
                <input
                  type="number"
                  // @ts-expect-error error
                  value={newItem["interval"]}
                  onChange={(e) => onChangeData("interval", e.target.value)}
                  placeholder={t("new_child.form_label.interval_days", {
                    nbDays: 2,
                  })}
                />
              </div>
            )
          }

          {withDates && (
            <>
              <div className="add-item">
                <p>{t("consultation.table_header.treatment_start_date")}</p>
                <input
                  type="date"
                  onChange={(e) => onChangeData("startDate", e.target.value)}
                />
              </div>
              <div className="add-item">
                <p>{t("consultation.table_header.treatment_end_date")}</p>
                <input
                  type="date"
                  onChange={(e) => onChangeData("endDate", e.target.value)}
                />
              </div>
            </>
          )}

          {viewNewItem ? (
            <div className="buttons-block flex flex-row self-center">
              <button
                className={`bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform ${
                  !canAddItem
                    ? "opacity-50 cursor-not-allowed bg-gray-400" // Style pour état disabled
                    : "hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200"
                } flex items-center space-x-2`}
                type="button"
                onClick={onConfirm}
                disabled={!canAddItem}
              >
                <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                  <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
                </span>
                <span>{t("buttons.add")}</span>
              </button>
              <button
                className="bg-gradient-to-r from-red-400 to-red-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-red-200 flex items-center space-x-2"
                type="button"
                onClick={() => setViewNewItem(false)}
              >
                <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                  <FontAwesomeIcon icon={faBan} style={{ color: "#ffffff" }} />
                </span>
                <span>{t("buttons.cancel")}</span>
              </button>
            </div>
          ) : (
            <button
              className="mt-4 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
              type="button"
              onClick={() => {
                setViewEditItem(false);
                add(newItem as never);
                setNewItem({});
                setHasChanged(true);
              }}
            >
              <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                <FontAwesomeIcon icon={faCheck} style={{ color: "#ffffff" }} />
              </span>
              <span>{t("buttons.confirm").toUpperCase()}</span>
            </button>
          )}
        </div>
      )}
      {!viewNewItem && !viewEditItem && (
        <div className="flex items-center gap-3">
          <button
            className="mt-4 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
            type="button"
            onClick={() => setViewNewItem(true)}
          >
            <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
              <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
            </span>
            <span>{t("buttons.add")}</span>
          </button>
          {onSave && hasChanged && (
            <button
              onClick={onSave}
              type="button"
              className="mt-4 bg-gradient-to-r from-[#4CAF50] via-[#66BB6A] to-[#81C784] text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-[#A5D6A7] flex items-center space-x-2"
            >
              <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{ color: "#ffffff" }}
                />
              </span>
              <span>{t("buttons.update")}</span>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default TableMedicationTreatment;
