/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable */
import React, { useState, useEffect } from "react";
import Camera from "../../assets/icons/73-removebg-preview.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "../../lib/config/axios";
import { useAuth } from "../../hooks/useAuth";
import ArrowLeft from "../../assets/icons/Arrow/ArrowLeft";
import ArrowRight from "../../assets/icons/Arrow/ArrowRight";
import { enqueueSnackbar, useSnackbar } from "notistack";
import Header from "../../component/Header";
import Accordion from "../../component/Accordion/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardImage } from "../../component/CardImage";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";

interface ImagesItem {
  id: string;
  symptomPictureId: number;
  symptomPicture: {
    id: number;
    category: string;
    url: string;
  };
  dateTime: string;
}

const ImagesModal = ({
  setOpen,
  setTemp,
  setTempData,
  picturesData,
  id,
  user,
}: any) => {
  const [selectedImage, setSelectedImage] = useState(-1);

  const toggleSelect = (id: number) => {
    setSelectedImage(id);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (selectedImage === -1) return;
    const response = await axios.post(
      `/children/${id}/symptom-picture-monitoring`,
      {
        symptomPictureId: selectedImage,
        dateTime: new Date().toISOString(),
      },
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );

    if (response.status === 201) {
      setOpen(false);
      setTemp((prevState: any) => [...prevState, response.data]);
      setTempData((prevState: any) => [...prevState, response.data]);
      enqueueSnackbar("L'image a été ajoutée avec succès", {
        autoHideDuration: 2000,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } else {
      enqueueSnackbar("Une erreur s'est produite", {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
      <div className="relative bg-white max-h-[450px] md:max-h-[650px] w-2/3 md:w-1/2 xl:w-1/3 p-8 rounded-lg shadow-lg overflow-y-scroll">
        <h2 className="text-lg font-semibold text-blue-400 mb-4">
          {t("monitoring.add_image")}
        </h2>
        <button
          className="absolute top-8 right-8 "
          onClick={() => setOpen(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <Accordion
          data={Object.entries(picturesData).map(([categoryName, items]) => {
            return {
              categoryName,
              //   @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              listOfPicture: items.map(({ id, url }) => ({
                id,
                url,
              })),
            };
          })}
          selected={selectedImage}
          toggleSelected={toggleSelect}
        />
        <div className="flex justify-center mt-4">
          <button
            onClick={handleSubmit}
            className="mt-4 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
          >
            <span>{t("buttons.validate")}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export const Images = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");
  const [temp, setTemp] = useState<ImagesItem[]>([]);
  const [tempData, setTempData] = useState<ImagesItem[]>([]);
  const { user } = useAuth();
  const [monitoringDate, setMonitoringDate] = useState(new Date());
  const [picturesData, setPicturesData] = useState<any>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchPictures = async () => {
      try {
        const response = await axios.get(`/symptom-picture`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        setPicturesData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPictures();
  }, [user]);

  useEffect(() => {
    const fetchTemp = async () => {
      try {
        const response = await axios.get(
          `/children/${child.id}/symptom-picture-monitoring`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        setTemp(response.data);
        setTempData(
          response.data.filter(
            (t: any) =>
              new Date(t.dateTime).toDateString() ===
              monitoringDate.toDateString()
          )
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchTemp();
  }, [child.id, user]);

  const handleNextDate = () => {
    setMonitoringDate(
      new Date(monitoringDate.setDate(monitoringDate.getDate() + 1))
    );
    setTempData(
      temp.filter(
        (t: any) =>
          new Date(t.dateTime).toDateString() === monitoringDate.toDateString()
      )
    );
  };

  const handlePreviousDate = () => {
    setMonitoringDate(
      new Date(monitoringDate.setDate(monitoringDate.getDate() - 1))
    );
    setTempData(
      temp.filter(
        (t: any) =>
          new Date(t.dateTime).toDateString() === monitoringDate.toDateString()
      )
    );
  };

  const dateFormated = (dateTime: string | number | Date) => {
    const dateObj = new Date(dateTime);
    return dateObj.toLocaleString(t("days.lg"), {
      hour: "numeric",
      minute: "numeric",
    });
  };

  const handleDeleteCard = async (id: string | undefined) => {
    if (!id) return;
    try {
      await axios.delete(
        `/children/${child.id}/symptom-picture-monitoring/${id}`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      setTempData((prevCards) => [
        ...prevCards.filter((card) => card.id !== id),
      ]);
      enqueueSnackbar(t("snackbar.data_deleted"), {
        autoHideDuration: 2000,
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } catch (error) {
      console.error("Failed to delete the card:", error);
      enqueueSnackbar(t("snackbar.data_delete_error"), {
        autoHideDuration: 2000,
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  return (
    <div className="w-screen">
      {open && (
        <ImagesModal
          setOpen={setOpen}
          setTemp={setTemp}
          setTempData={setTempData}
          monitoring="Image"
          picturesData={picturesData}
          id={id}
          user={user}
        />
      )}
      <section className="flex flex-col justify-between  w-full">
        <Header
          title={t("titles.monitoring")}
          titleColor="blue"
          childInfo={{
            picture: child.image_id,
            name: child.firstname,
            urlRedirection: `/child-profile/${child.id}`,
          }}
          showBackToMenu={true}
        />

        <div className="flex mt-4 flex-col md:flex-row items-center px-12 justify-between">
          <div className="flex flex-col items-center justify-center pt">
            <img
              src={Camera}
              alt="icon for images"
              className="w-14 h-14 lg:w-16 lg:h-16 transition-transform duration-300 hover:scale-110"
            />
            <span className="mt-2 lg:text-xl font-semibold text-center">
              {t("titles.images")}
            </span>
          </div>
          <button
            onClick={() => setOpen(true)}
            type="button"
            className="mt-4 bg-gradient-to-r from-blue-400 to-blue-300 text-white font-medium text-base py-2 px-6 rounded-lg shadow-md transition-all duration-200 ease-in-out transform hover:shadow-lg hover:scale-105 active:scale-95 active:shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-200 flex items-center space-x-2"
          >
            <span className="flex items-center justify-center w-6 h-6 bg-white bg-opacity-20 rounded-full transition-all duration-200">
              <FontAwesomeIcon icon={faPlus} style={{ color: "#ffffff" }} />
            </span>
            <span>{t("buttons.new_entry")}</span>
          </button>
        </div>
        <div className="flex flex-row justify-center items-center gap-x-6 md:gap-x-12 px-4 my-4">
          <button onClick={handlePreviousDate}>
            <ArrowLeft />
          </button>
          <h2 className="font-semibold text-blue-400 text-lg md:text-2xl text-center min-w-[200px] md:min-w-[400px]">
            {new Date(monitoringDate).toLocaleDateString(t("days.lg"), {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </h2>
          <button className="disabled:opacity-10" onClick={handleNextDate}>
            <ArrowRight />
          </button>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 my-4 lg:mt-6 gap-4 lg:gap-6 px-4 lg:px-6">
          {tempData.map((t, index) => (
            <div
              className="flex flex-row items-center justify-center"
              key={index}
            >
              <CardImage
                time={dateFormated(new Date(t.dateTime))}
                category={t.symptomPicture.category}
                url={t.symptomPicture.url}
                onDelete={() => handleDeleteCard(t.id)}
              />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
